import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Link,
  Button,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

import { BUTTON_PROPS } from '@/constants/colors';
import { ENABLE_SEND_GIFT } from '@/constants/feature-flags';

import txtWording from './locales';
import { THE_BRIDE } from '@/constants';

function ModalBank({ onClose, visible, onCopyText, lang }) {
  const address = `Cluster Florida, Pik 2 Extension. Florida 2 No. 58`;
  const receiver = THE_BRIDE;

  const renderAddress = () => {
    return (
      <Box padding="16px 0">
        <Text textAlign="center" color="secondaryColorText" marginTop="0" fontWeight="bold">
          {receiver}<br />
        </Text>
        <Text textAlign="center" color="black" fontSize="sm">
          {address}
        </Text>
        <Center>
          <Button
            size="xs"
            fontWeight="normal"
            colorScheme="blackAlpha"
            color="secondaryColorText"
            variant="outline"
            marginTop="16px"
            onClick={() => onCopyText(`${receiver} - ${address}`, txtWording.successCopyAddress[lang])}
          >
            {txtWording.modalCopyAddress[lang]}
          </Button>
        </Center>
        {false && (
          <Text textAlign="center" fontWeight="bold" marginTop="16px" color="secondaryColorText">
            Wedding Registry:{' '}
            <Link
              href="https://www.myregistry.com/wedding-registry/natashia-huey-and-erick-kurniawan-jakarta-daerah-khusus-ibukota-jakarta/3262823/giftlist"
              target="_blank"
            >
              Click Here
            </Link>
          </Text>
        )}
      </Box>
    );
  };

  return (
    <Modal size="full" onClose={onClose} isOpen={visible}>
      <ModalOverlay />
      <ModalContent maxWidth="500px">
        <ModalCloseButton marginTop="12px" {...BUTTON_PROPS} />
        <ModalHeader />
        <ModalBody>
          <Heading
            textAlign="center"
            fontWeight="normal"
            size="3xl"
            color="mainColorText"
            margin="24px 0 12px"
          >
            {txtWording.sendGift[lang]}
          </Heading>
          {ENABLE_SEND_GIFT && renderAddress()}
          <Box height="50px" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

ModalBank.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalBank.defaultProps = {
  lang: 'id',
};

export default ModalBank;