import React from 'react';
import { string } from 'prop-types';
import { Box, Center, Heading, Image } from '@chakra-ui/react';

import QRCard from '@components/Common/QRCard/Lazy';
import WithAnimation from '@components/Common/WithAnimation';

import { useGuest } from '@/context/guest';
// import generateShift from '@hooks/useShift';
import useInvitation from '@/hooks/useInvitation';

import {
  ASSETS_FLOWER_LEFT,
  IMG_RECEPTION,
  // IMG_DRESSCODE,
} from '@/constants/assets';
import { ENABLE_QR_INVITATION } from '@/constants/feature-flags';

import {
  // WEDDING_RESEPSI_FULLDATE,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  // WEDDING_RESEPSI_DRESSCODE,
  GOOGLE_MAPS_RESEPSI,
  WEDDING_RESEPSI_TIME,
} from '@/constants';

import Detail from './Detail';
import txt from './locales';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ lang, ...rest }) {
  const isInvitation = useInvitation();
  const { guest } = useGuest();
  // const shiftTime = generateShift(guest.shift);
  const { code } = guest;

  return (
    <Box padding="42px 24px" bgColor="bgPrimary" {...rest}>
      <Center>
        <Image src={ASSETS_FLOWER_LEFT} height="70px" marginLeft="-170px" marginBottom="-50px" />
      </Center>
      <WithAnimation>
        <Heading
          lineHeight="70%"
          fontWeight="normal"
          size="3xl"
          textAlign="center"
          letterSpacing="2px"
          marginBottom="32px"
          color="mainColorText"
        >
          <span dangerouslySetInnerHTML={{ __html: txt.title[lang] }} />
        </Heading>
      </WithAnimation>
      <Detail
        lang={lang}
        items={[
          // {
          //   title: ENABLE_MUSLIM_LANGUAGE ? txt.akad[lang] : txt.holy[lang],
          //   imgUrl: IMG_AKAD,
          //   date: WEDDING_AKAD_FULLDATE,
          //   time: WEDDING_AKAD_TIME,
          //   dresscode: WEDDING_AKAD_DRESSCODE,
          //   location: WEDDING_AKAD_LOC_NAME,
          //   locationRoad: WEDDING_AKAD_LOC_ROAD,
          //   locationUrl: isInvitation && GOOGLE_MAPS_AKAD,
          //   show: true,
          //   // info: `<i><b>*) Family only</b> </i>`,
          // },
          {
            title: 'TEAPAI',
            imgUrl: `https://ik.imagekit.io/masansgaming/Randy%20Karolins/5%20Teapai_hldnbkN1s.jpg?updatedAt=1703438936810`,
            date: 'Sunday, 07 January 2024',
            time: '16.30 WIB',
            dresscode: '',
            location: WEDDING_RESEPSI_LOC_NAME,
            locationRoad: WEDDING_RESEPSI_LOC_ROAD,
            locationUrl: GOOGLE_MAPS_RESEPSI,
            show: isInvitation,
            // info: `Sunset Cocktail | 17:30 WITA <br /> Dinner Reception | 18.30 WITA`,
            // dresscodeUrl: IMG_DRESSCODE,
          },
          {
            title: txt.reception[lang],
            imgUrl: IMG_RECEPTION,
            date: 'Sunday, 07 January 2024',
            time: WEDDING_RESEPSI_TIME,
            //dresscode: isInvitation ? WEDDING_RESEPSI_DRESSCODE : "",
            location: isInvitation ? WEDDING_RESEPSI_LOC_NAME : "",
            locationRoad: isInvitation ? WEDDING_RESEPSI_LOC_ROAD : "",
            locationUrl: isInvitation ? GOOGLE_MAPS_RESEPSI : "",
            info: isInvitation ? `*) Please don’t use white dress <br /> *) Don’t use stiletto ( heels lancip )` : '',
            show: true,
            // info: `Sunset Cocktail | 17:30 WITA <br /> Dinner Reception | 18.30 WITA`,
            // dresscodeUrl: IMG_DRESSCODE,
          },
        ]}
      />
      {code && ENABLE_QR_INVITATION && <QRCard marginTop="32px" />}
    </Box>
  );
}

WeddingSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(WeddingSection);
