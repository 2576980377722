import React, { useState, useEffect } from 'react';
import { bool, string } from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Textarea,
  Flex,
  Spacer,
  Image,
  // hooks
  useToast,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Wishlist from './WishlishCard';

import { useGuest } from '@/context/guest';
import useWishes from '@/usecase/use-wishes';
import useFormFields from '@hooks/useFormFields';
import useInvitation from '@hooks/useInvitation';

import txtWording from './locales';
import { BUTTON_PROPS } from '@/constants/colors';
import { IMG_WISHES } from '@/constants/assets';
import { INPUT_COMMON_PROPS, FORM_LABEL_PROPS, ERROR_TYPE } from './types';

function WishesSection({ lang }) {
  const { guest, isRegistered } = useGuest();
  const { code, name } = guest;
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  const { isLoading, isMutating, data, error, onPostWish, onRefetch } = useWishes();
  const isInvitation = useInvitation();

  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: `${!isRegistered && isInvitation ? '' : name}`,
    wish: '',
  });

  useEffect(() => {
    onResetForm();
  }, [isRegistered]);

  const toast = useToast();

  const handleSetAlert = (isSuccess) => {
    let messageTitle = txtWording.success[lang];
    let messageContent = txtWording.successMessage[lang];

    if (!isSuccess) {
      toast({ status: 'error', title: 'Oops!', description: txtWording.failedMessage[lang] });
    } else {
      toast({
        status: 'success',
        title: messageTitle,
        description: messageContent,
        isClosable: true,
      });
    }
  };

  /**
   * function to submit wishlist data
   * @param {FormEvent}
   * @returns {void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formFields.name || !formFields.wish) {
      setErrorType({
        name: !formFields.name && txtWording.requiredField[lang],
        wish: !formFields.wish && txtWording.requiredField[lang],
      });
      return;
    }

    await onPostWish({
      code,
      name: formFields.name,
      wish: formFields.wish,
      onError: () => {
        handleSetAlert(false);
      },
      onSuccess: () => {
        onResetForm();
        handleSetAlert(true);
        setErrorType(ERROR_TYPE);
        onRefetch();
      },
    });
  };

  return (
    <Box>
      <Box padding="32px 24px 62px 24px" bgColor="bgPrimary">
        {/* Title & Description sections */}
        <Box bgColor="bgSecondary" padding="24px 16px" borderTopRadius="16px">
          <Box display="flex" alignItems="center" textAlign="center" color="bgPrimary">
            <Image
              src={IMG_WISHES}
              objectFit="cover"
              width="80px"
              height="80px"
              objectPosition="center"
              borderRadius="50%"
              marginRight="20px"
            />
            <Box width="calc(100% - 100px)">
              <Heading fontWeight="normal" color="bgPrimary" fontSize={lang === 'id' ? '3xl' : '2xl'}>
                {txtWording.title[lang]}
              </Heading>
            </Box>
          </Box>
        </Box>
        <Box width="100%" height="2px" bgColor="bgPrimary" />
        <Box padding="24px 24px 42px 24px" borderBottomRadius="16px" bgColor="bgSecondary">
          {/* Box for FORM */}
          <WithAnimation>
            <Box paddingTop="2">
              <FormControl margin="8px 0" isInvalid={errorType.name}>
                <FormLabel {...FORM_LABEL_PROPS}>{txtWording.name[lang]}:</FormLabel>
                <Input
                  {...INPUT_COMMON_PROPS}
                  onChange={createChangeHandler('name')}
                  value={formFields.name}
                />
                <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
              </FormControl>
              <FormControl margin="16px 0" isInvalid={errorType.wish}>
                <FormLabel {...FORM_LABEL_PROPS}>{txtWording.wish[lang]}:</FormLabel>
                <Textarea
                  {...INPUT_COMMON_PROPS}
                  onChange={createChangeHandler('wish')}
                  value={formFields.wish}
                />
                <FormErrorMessage marginTop="4px">{errorType.wish}</FormErrorMessage>
              </FormControl>
              <Flex justifyItems="end">
                <Spacer />
                <Button
                  isLoading={isMutating}
                  size="sm"
                  padding="8px 24px"
                  fontWeight="light"
                  bgColor="bgAlternative"
                  fontFamily="body"
                  borderRadius="4px"
                  textTransform="uppercase"
                  letterSpacing="2px"
                  color="white"
                  onClick={handleSubmit}
                  {...BUTTON_PROPS}
                >
                  {txtWording.send[lang]}
                </Button>
              </Flex>
            </Box>
          </WithAnimation>
          {!error && (
            <WithAnimation>
              <Wishlist wishlistData={data} loading={isLoading} />
            </WithAnimation>
          )}
        </Box>
      </Box>
    </Box>
  );
}

WishesSection.propTypes = {
  lang: string,
  inverse: bool,
};

WishesSection.defaultProps = {
  lang: 'en',
  inverse: false,
};

export default React.memo(WishesSection);
